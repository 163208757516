<template>
  <b-card
    no-body
    :bg-variant="isActive ? 'default' : 'transparent'"
  >

    <b-card-header>
      <!--      <b-card-title>-->
      <!--        <label-->
      <!--          v-t="`inputs.payment_method`"-->
      <!--          class="section-label"-->
      <!--        />-->
      <!--      </b-card-title>-->

      <i-input
        :errors="errors"
        type="select"
        title="inputs.payment_method"
        class="w-100"
        :validation-rules="['required']"
        :options="options"
        :clearable="true"
        :value="selected.name"
        label="name"
        :disabled="readonly"
        @input=" val => $emit('update:selected', val || {})"
      />
    </b-card-header>

    <b-card-footer
        v-for="paymentData in (paymentsData || []).filter(p => p.extra_data && Object.keys(p.extra_data).length > 0)"
        :key="paymentData.id"
    >
      <div
          v-t="`modules.orders._create.payment_data`"
          class="detail-title detail-total"
      />
      <div
          v-if="paymentData.extra_data.sender_name"
          class="text-muted"
      >
        <feather-icon
            icon="UserIcon"
            class="mr-75"
        />
        <span>{{ $t('modules.orders._create.sender_name') }}:</span>
        <span>{{ paymentData.extra_data.sender_name }}</span>
      </div>
      <div
          v-if="paymentData.extra_data.transfer_statement"
          class="text-muted"
      >
        <feather-icon
            icon="FileIcon"
            class="mr-75"
        />
        <span>{{ $t('modules.orders._create.bank_statements') }}:</span>
        <span><a
            :href="paymentData.extra_data.transfer_statement"
            target="_blank"
        >{{ $t('Show') }}</a></span>
      </div>

    </b-card-footer>

    <b-card-footer
      v-if="showStatus"
    >
      <div
        v-t="`modules.orders._create.payment_status`"
        class="detail-title detail-total"
      />
      <b-dropdown
        id="dropdown-payment-status"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :text="$t(paidAt ? `modules.payments.status.paid` : `modules.payments.status.not_paid`)"
        :variant="resolveBooleanStatusVariant(paidAt)"
        class="btn-block"
        :disabled="statusUpdateInProgress"
      >
        <b-dropdown-item
          :key="`dropdown-payment-status`"
          @click="$emit('status-update-requested', !paidAt)"
        >
          {{ $t(paidAt ? `modules.payments.status.not_paid` : `modules.payments.status.paid`) }}
        </b-dropdown-item>
      </b-dropdown>
      <div
        v-if="paidAt"
        class="text-muted"
      >
        <feather-icon
          icon="CalendarIcon"
          class="mr-75"
        />
        <span>{{ $t(`modules.payments.details.paid_at`) }}: {{ formatDatetime(paidAt) }}</span>
      </div>

    </b-card-footer>

  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardFooter, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { ref } from 'vue'
import Ripple from 'vue-ripple-directive'
import useModelList from '@/views/models/common/useModelList'
import { formatDatetime } from '@core/utils/filter'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardFooter,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    options: {
      type: Array,
      default: Array,
    },
    selected: {
      type: Object,
      default: Object,
    },
    errors: {
      type: Array,
      default: Array,
      required: false,
    },
    paidAt: {
      type: [String, null],
      default: null,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
      required: false,
    },
    statusUpdateInProgress: {
      type: Boolean,
      default: false,
      required: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    paymentsData: {
      type: Array,
      default: Array,
    },
  },
  emits: ['status-update-requested'],
  methods: { formatDatetime },
  setup(_, { emit }) {
    const paymentMethodOptions = ref([{ value: 'COD', label: 'COD' }])

    const {
      resolveBooleanStatusVariant,
    } = useModelList('customers', [])

    return {
      paymentMethodOptions,
      resolveBooleanStatusVariant,
    }
  },
}
</script>

<style lang="scss">
</style>
